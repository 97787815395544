import { computed, effect } from '@preact/signals';
import toast from 'react-hot-toast';
import {
  AudioState,
  currentDuration,
  currentEpisode,
  currentSeek,
  getRequestedState,
  getState,
  state,
} from '../models/audio-signals';
import debugLog from '../models/debug';
import { DownloadsStore } from '../models/downloads';
import { Episode, EpisodeCompoundId, usePodcastsStore } from '../models/podcasts';
import {
  syncUsageDataNow,
  totalAdSkipsLeft,
  trackAdSkipUnlessRecent,
} from '../models/usage-data';
import { getUserDataId, UserDataStore, useUserDataStore } from '../models/user-data';
import { objectEntries, objectFromEntries } from '../utils/ts-extras';
import {
  checkEpisodeAfterFailure,
  reportError,
  showMessageWhenPlaybackFails,
} from './error-handling';
import getThumbUrl from './get-thumb';
import { exposeOnWindow, waitForEvent } from './helpers';

const log = (...args: any[]) => {
  debugLog({ logName: 'audio', logColor: 'DodgerBlue' }, ...args);
};

const sendMessage = (obj: Object) => {
  // log('Client -> iOS', obj);
  window.webkit!.messageHandlers.audio.postMessage(JSON.stringify(obj));
};

class IOSAudioPlayer {
  appVersion?: string;

  constructor() {
    log('Using iOS Audio Player');

    const swiftState = { state: '', requestedState: '' };
    effect(() => {
      const state = getState();
      const requestedState = getRequestedState();
      if (state !== swiftState.state || requestedState !== swiftState.requestedState) {
        // Only update Swift if need be. No need to send unnecessary messages
        swiftState.state = state;
        swiftState.requestedState = requestedState;
        sendMessage({
          command: 'updateState',
          state,
          requestedState,
        });
      }
    });

    addEventListener('IOS_APP_VERSION', ((
      event: CustomEvent<{ appVersion: string }>
    ) => {
      const { appVersion } = event.detail;
      this.appVersion = appVersion;
      log(`Native app version ${appVersion}`);
      window.iosAppVersion = parseFloat(appVersion);
    }) as EventListener);

    addEventListener('UPDATE_AUDIO_STATE', ((
      event: CustomEvent<{ state: AudioState }>
    ) => {
      const newState = event.detail.state;
      if (!newState) throw new Error(`Invalid state from Swift: ${newState}`);
      state.value = newState;
      swiftState.state = newState;
    }) as EventListener);

    addEventListener('UPDATE_SEEK', ((event: CustomEvent<{ seek: number }>) => {
      const { seek } = event.detail;
      if (typeof seek !== 'number') throw new Error(`Invalid seek from Swift: ${seek}`);
      currentSeek.value = seek;
    }) as EventListener);

    addEventListener('UPDATE_DURATION', ((event: CustomEvent<{ duration: number }>) => {
      const { duration } = event.detail;
      if (typeof duration !== 'number')
        throw new Error(`Invalid duration from Swift: ${duration}`);
      currentDuration.value = duration;
    }) as EventListener);

    addEventListener('SEEK_FROM_LAST_SESSION', ((
      event: CustomEvent<{ seek: number; timestampMs: number; url: string }>
    ) => {
      // Used to set initial value for seek assuming initial episode hasn't changed
      // We store this value natively b/c occasionally the webview gets backgrounded and doesn't
      // get updated seek values even while player is playing
      exposeOnWindow({
        seekFromLastSession: [
          event.detail.seek,
          event.detail.timestampMs,
          event.detail.url,
        ],
      });
    }) as EventListener);

    addEventListener('LATEST_CUSTOMER_INFO', ((
      event: CustomEvent<{ customerInfo: unknown } | { error: unknown }>
    ) => {
      if ('customerInfo' in event.detail) {
        log('RevenueCat customer data', event.detail.customerInfo);
      } else {
        log('RevenueCat unable to get customer data', event.detail.error);
      }
      syncUsageDataNow();
    }) as EventListener);

    addEventListener('AD_SKIPPED_NATIVELY', ((
      event: CustomEvent<{
        episodeId: EpisodeCompoundId;
        startTime: number;
        endTime: number;
      }>
    ) => {
      const { episodeId, startTime, endTime } = event.detail;
      return trackAdSkipUnlessRecent(episodeId, startTime, endTime);
    }) as EventListener);

    effect(() => {
      sendMessage({
        command: 'updateAdSkipsLeft',
        adSkipsLeft: totalAdSkipsLeft.value || 0,
      });
    });

    this.setupUserIdSync();
    this.setupEpisodeAdsSync();

    sendMessage({ command: 'readyForEvents' });
  }

  loadNewEpisode(episode: Episode, seek: number, playSkipAdSound = false) {
    // Set in case Swift doesn't set it
    if (episode.duration) currentDuration.value = episode.duration;

    const podcast = usePodcastsStore.getState().podcasts[episode.podcastId];
    const metadata = {
      id: episode.id,
      title: episode.title,
      artist: podcast.artistName,
      podcastName: podcast.name,
      pubDateTimestamp: Math.round(episode.pubDate.getTime() / 1000),
      imageUrl: getThumbUrl(podcast, 600),
      ads: episode.ads,
    };

    // Load new episode & update metadata
    sendMessage({
      command: 'loadNewEpisode',
      audioUrl: episode.audioUrl,
      seek,
      playSkipAdSound,
      duration: episode.duration, // @todo -> handle missing duration...
      ...metadata,
    });
  }

  reset() {
    sendMessage({ command: 'reset' });
  }

  play() {
    // Should never happen (but will keep Typescript happy)
    if (!currentEpisode.value) {
      return reportError('play() called without episode');
    }

    sendMessage({ command: 'play' });

    // Handle case where user is trying to attempt playback from an unloaded state
    if (state.value === 'unloaded') {
      this.loadNewEpisode(currentEpisode.value, 0);
    }

    const episodeId = currentEpisode.value!.id;

    // If not playing soon, investigate why
    const seconds = 3;
    const shouldBePlayingButIsNot = () =>
      getRequestedState() === 'playing' &&
      state.value === 'loading' &&
      episodeId === currentEpisode.value!.id;

    setTimeout(() => {
      if (shouldBePlayingButIsNot()) {
        log(`Episode still not playing after ${seconds} second(s)`);
        checkEpisodeAfterFailure(currentEpisode.value!, 'play_episode').then(
          ({ isSaved, status }) => {
            if (shouldBePlayingButIsNot()) {
              showMessageWhenPlaybackFails(isSaved, status, 'play');
              if (!isSaved) {
                this.reset();
              }
            }
          }
        );
      }
    }, seconds * 1000);
  }

  pause() {
    sendMessage({ command: 'pause' });
    // this.element.pause();
    // state.value = 'paused';
  }

  seekTo(seconds: number, playSkipAdSound = false) {
    sendMessage({ command: 'seekTo', seconds, playSkipAdSound });
  }

  playFailToSkipAdSound() {
    sendMessage({ command: 'playFailToSkipAdSound' });
  }

  async updateSeek() {
    // Ask Swift for seek and receive response via event
    sendMessage({ command: 'updateSeek' });

    try {
      await waitForEvent(window, 'UPDATE_SEEK', 200);
    } catch (err: any) {
      reportError(err.message);
    }
  }

  updatePlaybackRate(rate: number) {
    sendMessage({ command: 'updatePlaybackRate', rate });
  }

  //
  // Downloading audio
  //

  downloadAudioFile(episode: Episode) {
    sendMessage({ command: 'downloadAudioFile', url: episode.audioUrl });
  }

  deleteAudioFile(episode: Episode) {
    sendMessage({ command: 'deleteAudioFile', url: episode.audioUrl });
  }

  async checkDownloadedFiles(
    getDownloadsState: () => DownloadsStore,
    getUserDataState: () => UserDataStore
  ) {
    log('Checking iOS audio downloads...');

    // Downloads in state (should match cache)
    const { downloadsProgress, setProgress } = getDownloadsState();
    // Downloads that user has requested
    const { downloadedEpisodeIds } = getUserDataState();
    const { idToEpisode } = usePodcastsStore.getState();

    const downloadedIdsToAudioUrlMap = idsToAudioUrlMap(downloadedEpisodeIds);

    sendMessage({
      command: 'getDownloadedFilesInfo',
      urls: Object.values(downloadedIdsToAudioUrlMap),
    });

    const {
      detail: { storedPaths, urlFilenamePairs },
    } = (await waitForEvent(window, 'DOWNLOAD_FILES_INFO', 1000)) as CustomEvent<{
      storedPaths: string[];
      urlFilenamePairs: [string, string][];
    }>;

    // Download any episodes that should be in cache but aren't
    // Never queue up more than 2 episodes at a time
    let queuedEpisodeCount = 0;
    objectEntries(downloadedIdsToAudioUrlMap).forEach(([id, audioUrl]) => {
      const filename = urlFilenamePairs.find((pair) => pair[0] === audioUrl)![1];
      // log({ filename });
      if (storedPaths.find((path) => path.includes(filename))) {
        // Ensure downloadsProgress is set to 1
        if (!downloadsProgress[id] || downloadsProgress[id][0] < 1) {
          setProgress(id, 1);
        }
      } else if (
        !downloadsProgress[id] ||
        Date.now() - downloadsProgress[id][1] > 30 * 1000
      ) {
        log('URL has no progress or is stalled', id, audioUrl);
        // Episode has no progress -or- has stalled
        if (queuedEpisodeCount < 2) {
          const episode = idToEpisode(id);
          if (episode) {
            console.warn('Force download missing episode audio', id);
            this.downloadAudioFile(episode);
            queuedEpisodeCount++;
          } else {
            reportError(
              "Unable to download missing episode audio: can't find episode",
              id
            );
          }
        }
      }
    });

    // Remove any persisted episodes that shouldn't be saved anymore
    storedPaths
      .filter((path) => !urlFilenamePairs.find((pair) => path.includes(pair[1])))
      .forEach((path) => {
        log('Deleting file that should no longer be cached', path);
        sendMessage({ command: 'deleteAudioFile', path });
      });

    function idsToAudioUrlMap(
      ids: EpisodeCompoundId[]
    ): Record<EpisodeCompoundId, string> {
      return objectFromEntries(
        ids
          .map((id) => {
            const audioUrl = idToAudioUrl(id);
            return (audioUrl ? [id, audioUrl] : undefined) as [string, string];
          })
          .filter(Boolean)
      );
    }

    function idToAudioUrl(id: string) {
      const episode = idToEpisode(id);
      return episode ? episode.audioUrl : undefined;
    }
  }

  //
  // Getting logs / debugging
  //

  async getNativeLogs() {
    sendMessage({ command: 'getSwiftLogs' });
    // Wait up to 40s for Swift logs. Still getting timeouts at 30s. Recently had some logs come in after 27s (feedback #157)
    const maxWaitMs = 40 * 1000;
    log(`Waiting ${maxWaitMs / 1000}s for iOS logs`);
    const {
      detail: { logs },
    } = (await waitForEvent(window, 'RECENT_LOGS', maxWaitMs)) as CustomEvent<{
      logs: string[];
    }>;
    log('Got iOS logs', logs.length);
    return logs;
  }

  //
  // RevenueCat user subscription
  //

  setupUserIdSync() {
    // Wait for userDataStore to be setup
    setTimeout(() => {
      let lastSyncedUserId: string | null = null;
      const syncUserIdIfChanged = () => {
        const userId = getUserDataId();
        if (userId && userId !== lastSyncedUserId) {
          sendMessage({ command: 'updateUserId', userId });
          lastSyncedUserId = userId;
        }
      };
      syncUserIdIfChanged();
      useUserDataStore.subscribe(syncUserIdIfChanged);
    });
  }

  restorePurchase() {
    sendMessage({ command: 'restorePurchase' });
  }

  async makePurchase(offeringId: string, packageId: string) {
    sendMessage({
      command: 'makePurchase',
      offeringId,
      packageId,
    });
    // Wait for user to complete transaction for up to 15 minutes
    await waitForEvent(window, 'PURCHASE_STATUS', 15 * 60 * 1000).then((event) => {
      const result = (
        event as CustomEvent<{ transaction: unknown } | { error: unknown }>
      ).detail;
      log('Purchase result', result);
      if ('error' in result) {
        if (result.error !== 'user_cancelled') {
          toast.error(`Payment failed: ${result.error}`);
        }
      } else {
        toast.success('Payment successful! Updating your account...');
      }
      syncUsageDataNow();
    });
  }

  setupEpisodeAdsSync() {
    // Let iOS know when current episode's ads change (could happen *after* loading episode so loadNewEpisode call is not enough)
    const currentEpisodeAdsSignal = computed(() => {
      const episode = currentEpisode.value;
      return episode
        ? JSON.stringify({
            id: episode.id,
            ads: episode.ads,
          })
        : null;
    });
    effect(() => {
      if (!currentEpisodeAdsSignal.value) return;
      const { id, ads } = JSON.parse(currentEpisodeAdsSignal.value);
      sendMessage({ command: 'updateEpisodeAds', id, ads });
    });
  }
}

export default IOSAudioPlayer;
