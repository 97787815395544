import { round } from 'lodash-es';
import toast from 'react-hot-toast';
import { default as Collection } from 'timestamp-collection';
import create from 'zustand';
import { fetchEpisode } from '../utils/api';
import createUserDataCollections from '../utils/create-user-data-collections';
import { reportError } from '../utils/error-handling';
import { exposeOnWindow } from '../utils/helpers';
import { getInitialValue, setLazyValue } from '../utils/localstorage';
import { syncUserDataLazily } from '../utils/sync-user-data';
import { useDownloadsStore } from './downloads';
import { Episode, EpisodeCompoundId, Podcast, PodcastId } from './podcasts';

export type UserDataStore = {
  // Used to ensure we sync user data to correct user
  userId: string | null;
  // Serializable user data synced to API as JSON patches
  followedPodcastIds: PodcastId[]; // ['248021']
  podcastsLastViewedEpisodeDate: Record<PodcastId, number>; // {'248021': 133532431}
  // {'248021|a1dbe300': [23.3, 0]} 23.3s played, 0s left
  // {'248021|a1dbe300': [0, 0]} Marked as played
  episodesProgress: Record<EpisodeCompoundId, [number, number]>;
  episodesProgressSaveTimes: Record<EpisodeCompoundId, number>;
  listenLaterEpisodeIds: EpisodeCompoundId[]; // ['248021|a1dbe300']
  starredEpisodeIds: EpisodeCompoundId[]; // ['248021|a1dbe300']
  recentEpisodeIds: EpisodeCompoundId[]; // ['248021|a1dbe300']
  downloadedEpisodeIds: EpisodeCompoundId[]; // ['248021|a1dbe300']
};
type K = keyof UserDataStore | string;

const {
  followedPodcastIds,
  podcastsLastViewedEpisodeDate,
  episodesProgress,
  listenLaterEpisodeIds,
  starredEpisodeIds,
  recentEpisodeIds,
  downloadedEpisodeIds,
} = createUserDataCollections(importLocalData);

export const useUserDataStore = create<UserDataStore>()(() => ({
  userId: getInitialValue(
    'UserDataStore__userId',
    (val) => typeof val === 'string' && val.length > 0,
    null
  ),
  followedPodcastIds: followedPodcastIds.getValue(),
  podcastsLastViewedEpisodeDate: podcastsLastViewedEpisodeDate.getValue(),
  episodesProgress: episodesProgress.getValue().progress,
  episodesProgressSaveTimes: episodesProgress.getValue().saveTimes,
  listenLaterEpisodeIds: listenLaterEpisodeIds.getValue() as EpisodeCompoundId[],
  starredEpisodeIds: starredEpisodeIds.getValue() as EpisodeCompoundId[],
  recentEpisodeIds: recentEpisodeIds.getValue() as EpisodeCompoundId[],
  downloadedEpisodeIds: downloadedEpisodeIds.getValue() as EpisodeCompoundId[],
}));

const collectionConfigs: Record<
  K,
  { collection: Collection<any>; getValue: () => any }
> = {
  followedPodcastIds,
  podcastsLastViewedEpisodeDate,
  episodesProgress,
  listenLaterEpisodeIds,
  starredEpisodeIds,
  recentEpisodeIds,
  downloadedEpisodeIds,
};

//
// Export methods for updating entry groups
//

export function bumpLastViewedEpisodeDate(episode: Episode) {
  const timestamp = episode.pubDate.getTime();
  podcastsLastViewedEpisodeDate.collection.add(episode.podcastId.toString(), timestamp);
}

export function addToListenLater(episode: Episode) {
  listenLaterEpisodeIds.collection.add(episode.id);
  // Additionally, get ads and in the process, bump job priority
  if (!episode.ads) {
    fetchEpisode(episode.podcastId, episode.guidHash, 'listen_later').catch((error) => {
      console.error('Listen later job priority bump failed', error);
    });
  }
  // Additionally, download episode if not already downloaded
  useDownloadsStore.getState().downloadEpisode(episode);
  // Confirm action w/ notification
  toast.success('Added to Listen Later');
}

export function removeFromListenLater(episode: Episode) {
  const compoundId = episode.id;
  listenLaterEpisodeIds.collection.remove(compoundId);
  // Additionally, remove episode from downloads
  useDownloadsStore.getState().removeDownload(compoundId);
}

export function setEpisodeProgress(
  episode: Episode,
  seek: number,
  duration: number,
  updateTimestamp = Date.now()
) {
  const roundSeek = (value: number) => {
    // Round to 2 decimals
    const rounded = round(value, 2);
    // If slightly below zero, round up to zero (shouldn't ever be negative but sometimes is)
    // Note: the "> -1 condition" is important b/c "seek = -1" is a default value (unplayed I think)
    if (rounded < 0 && rounded > -1) return 0;
    // If larger than duration, set to end of episode
    if (rounded > duration) {
      return duration;
    }
    return rounded;
  };
  seek = roundSeek(seek);

  let timeLeft = round(duration - seek, 2);
  if (timeLeft < 0) timeLeft = 0;

  const progress: [number, number] = [seek, timeLeft];
  episodesProgress.collection.add(episode.id, updateTimestamp, progress);
}

export function setPlayed(episode: Episode) {
  episodesProgress.collection.add(episode.id, Date.now(), [0, 0]);
}

export function setUnPlayed(episode: Episode) {
  episodesProgress.collection.remove(episode.id);
}

export function followPodcast(podcast: Podcast) {
  followedPodcastIds.collection.add(podcast.id.toString());
  toast.success(`Followed "${podcast.name}"`);
}

export function unfollowPodcast(podcast: Podcast) {
  followedPodcastIds.collection.remove(podcast.id.toString());
  toast(`Unfollowed "${podcast.name}"`);
}

export function addToDownloads(episodeCompoundId: string) {
  downloadedEpisodeIds.collection.add(episodeCompoundId);
}

export function removeFromDownloads(episodeCompoundId: string) {
  downloadedEpisodeIds.collection.remove(episodeCompoundId);
}

export function getDownloadRequestedTimestamp(episodeCompoundId: string) {
  const entry = downloadedEpisodeIds.collection.get((value) => value)[
    episodeCompoundId
  ];
  return entry ? entry[0] : undefined;
}

export function addToRecentEpisodes(episodeCompoundId: string) {
  recentEpisodeIds.collection.add(episodeCompoundId);
}

export function removeFromRecentEpisodes(episodeCompoundId: string) {
  recentEpisodeIds.collection.remove(episodeCompoundId);
}

export function clearAllUserData() {
  Object.values(collectionConfigs).forEach(({ collection }) => collection.clear());
}

export function getUserDataId() {
  return useUserDataStore.getState().userId;
}

export function setUserDataId(userId: string | null) {
  useUserDataStore.setState({ userId });
  setLazyValue('UserDataStore__userId', userId);
}

//
// Update UI and API when user data changes
//

Object.entries(collectionConfigs).forEach(([name, { collection, getValue }]) => {
  // @ts-ignore Update Zustand store when items are added/removed from entry groups
  collection.subscribe(() => {
    const value = getValue();
    if (name === 'episodesProgress') {
      useUserDataStore.setState({
        episodesProgress: value.progress,
        episodesProgressSaveTimes: value.saveTimes,
      });
    } else {
      useUserDataStore.setState({ [name]: value });
    }
  });
});

// Keep track of currently persisted hashes
const persistedHashes: Record<K, string> = Object.fromEntries(
  Object.entries(collectionConfigs).map(([name, { collection }]) => [
    name,
    collection.hash,
  ])
);

export const collections = Object.entries(collectionConfigs).map(
  ([name, { collection }]) => ({
    name,
    collection,
  })
);

// Persist state in LocalStorage & sync with API
useUserDataStore.subscribe(() => {
  const configsToSave = Object.entries(collectionConfigs).filter(
    ([name, { collection }]) => collection.hash !== persistedHashes[name]
  );
  // Save each entry group to LocalStorage individually
  configsToSave.forEach(([name, { collection }]) => {
    setLazyValue(`UserDataStore__${name}`, () => collection.export());
  });
  // Lazily save entry group updates to API with a single request
  // All entry groups hashes are sent to API, but in most cases, they won't all have updates
  syncUserDataLazily();
});

// Helper for getting initial data from Storage
function importLocalData(name: string, collection: Collection<any>) {
  const data = getInitialValue(`UserDataStore__${name}`);
  if (!data) return;

  try {
    collection.import(data);
  } catch (error) {
    reportError(error, `Collection ${name} LocalStorage import failed`, data);
  }
}

exposeOnWindow({ useUserDataStore });
