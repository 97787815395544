import {
  AuthError,
  createClient,
  Session,
  SignInWithOAuthCredentials,
  SignInWithPasswordCredentials,
  SignUpWithPasswordCredentials,
} from '@supabase/supabase-js';
import { route } from 'preact-router';
import toast from 'react-hot-toast';
import create from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { reportError } from '../utils/error-handling';
import { exposeOnWindow, isIOS } from '../utils/helpers';
import { getInitialValue, safeLocalStorage, setLazyValue } from '../utils/localstorage';
import { syncUserDataWithAPI } from '../utils/sync-user-data';
import { currentEpisode } from './audio-signals';
import debugLog from './debug';
import { showModal, useSessionStore } from './session';
import { syncUsageDataNow, syncUsageDataToApi, usageActions } from './usage-data';
import { clearAllUserData, getUserDataId, setUserDataId } from './user-data';

const log = (...args: any[]) => {
  debugLog({ logName: 'user', logColor: 'violet' }, ...args);
};

if (!process.env.SUPABASE_KEY) {
  throw new Error('SUPABASE_KEY missing');
}

export const supabase = createClient(
  'https://didgjhjnevdixtjltmho.supabase.co', // API URL
  process.env.SUPABASE_KEY
);

export type User = {
  id: string;
  email?: string;
  name?: string;
  provider?: string;
  pictureUrl?: string;
  accessToken: string;
  createdAt: string;
  confirmedAt: string;
};

type UserStore = {
  user: User | null;
  signedInState: 'loading' | 'signed-in' | 'signed-out';
  lastSignIn?: { time: number; redirectRoute: string; hasRedirected: boolean }; // URL is the one before the sign-in/sign-up page
  actions: {
    signInWithPassword: (obj: SignInWithPasswordCredentials) => Promise<void>;
    signInWithOAuth: (obj: SignInWithOAuthCredentials) => Promise<void>;
    signUpWithPassword: (obj: SignUpWithPasswordCredentials) => Promise<void>;
    signOut: () => void;
    resetPassword: (email: string) => Promise<any>;
    updatePassword: (newPassword: string) => Promise<any>;
    updateLastSignIn: (hasRedirected?: boolean) => void;
  };
};

// Ignore duplicate SIGNED_IN events by keeping track of last event & session hash
let lastSignedInEventSessionHash = '';

// Distinguish intentional sign-out action vs it happening automatically when
// access token expired or LocalStorage cleared
let signOutTime = 0;

const useUserStore = create<UserStore>()(
  subscribeWithSelector((set, get) => ({
    user: getInitialValue(
      'UserStore__user',
      (val: any) => val && val.id && val.email,
      null
    ),
    lastSignIn: getInitialValue(
      'UserStore__lastSignIn',
      (val: any) => val && val.time && Number.isInteger(val.time) && val.redirectRoute
    ),
    signedInState: 'loading',
    actions: {
      signInWithPassword: (obj) =>
        // Sign in with username & password
        supabase.auth.signInWithPassword(obj).then(({ error }) => {
          if (error) {
            log('Sign In failed', error);
            const errorMessage =
              error.message === 'Invalid login credentials'
                ? 'Incorrect email or password'
                : error.message;
            toast.error(`Sign in failed: ${errorMessage}`, {
              duration: 10 * 1000,
            });
          } else {
            get().actions.updateLastSignIn();
          }
        }),
      signInWithOAuth: (obj) =>
        // Sign in with username & password
        supabase.auth
          .signInWithOAuth({
            ...obj,
            options: {
              redirectTo: `${location.origin}${getPostSignInRedirectRoute()}`,
            },
          })
          .then(({ error }) => {
            if (error) {
              log('Sign In failed', error);
              toast.error(`Sign in failed: ${error.message}`, {
                duration: 10 * 1000,
              });
            } else {
              // oAuth flow will already redirect them to correct page specified
              // by redirectTo parameter (as long as it's whitelisted in Supabase)
              get().actions.updateLastSignIn(true);
            }
          }),
      signUpWithPassword: (obj) =>
        // Sign up with username & password
        supabase.auth.signUp(obj).then(({ data, error }) => {
          if (error) {
            log('Sign up failed', error);
            const messageForUser =
              error.message === 'Email rate limit exceeded'
                ? `Sorry, we've had too many signups in the past hour. Please try again in an hour or two.`
                : `Sign up failed: ${error.message}`;
            toast.error(messageForUser, { duration: 10 * 1000 });
          } else if (data.session) {
            get().actions.updateLastSignIn();
          } else {
            log('Sign up succeeded, but needs to confirm password');
            toast.success('Thanks! Please confirm your email to finish signing up.', {
              duration: 10 * 1000,
            });
          }
        }),
      signOut: () => {
        // Sync current user's data to API & clear it from browser
        syncUserDataWithAPI();
        clearAllUserData();
        currentEpisode.value = undefined;
        // Sync usage data to API & clear it from browser
        syncUsageDataToApi();
        usageActions.clearUsageAndSubscriptionData();
        // Associate future state with anonymous user
        setUserDataId(null);
        // Sign out
        signOutTime = Date.now();
        supabase.auth.signOut().then(
          () => {
            log('Signed out successfully');
          },
          (error: AuthError) => {
            log('Failed to sign out', error);
            reportError(error);
          }
        );
      },
      resetPassword: (email) =>
        supabase.auth
          .resetPasswordForEmail(email, {
            redirectTo: `${location.origin}/update-password`,
          })
          .then(({ error }) => {
            if (error) {
              log('Failed to send reset password email', error);
              toast.error(`Failed to send reset password email: ${error.message}`, {
                duration: 10 * 1000,
              });
            } else {
              toast.success(`Please check your email!`, {
                duration: 10 * 1000,
              });
            }
          }),
      updatePassword: (newPassword) =>
        supabase.auth
          .updateUser({
            password: newPassword,
          })
          .then(({ error }) => {
            if (error) {
              log('Failed to update password', error);
              throw error;
            } else {
              log('Successfully updated password');
            }
          }),
      updateLastSignIn: (hasRedirected = false) => {
        const redirectRoute = getPostSignInRedirectRoute();
        const lastSignIn = {
          time: Date.now(),
          redirectRoute,
          hasRedirected: true,
        };
        set({ lastSignIn });
        if (!hasRedirected) {
          // Redirect user to correct route
          route(redirectRoute);
        }
      },
    },
  }))
);

exposeOnWindow({ _supabase: supabase, _useUserStore: useUserStore });

// Set initial user (if user is already logged-in)
supabase.auth
  .getSession()
  .then(({ data: { session } }) => {
    useUserStore.setState({ user: sessionToUser(session), signedInState: 'signed-in' });
  })
  .catch((error) => {
    log('Failed to get initial user session', error);
  })
  .then(() => {
    if (!getUser()) {
      useUserStore.setState({ signedInState: 'signed-out' });
    } else {
      // Request ad-skips and customer status
      syncUsageDataNow();
    }
  });

// Expose state to rest of app
export const getUser = () => useUserStore.getState().user;
export const useUser = () => useUserStore((s) => s.user);
export const useIsAppDeveloper = () =>
  useUserStore((s) =>
    Boolean(s.user && s.user.email && s.user.email.startsWith('micah.millereshleman'))
  );
export const useSignInState = () => useUserStore((s) => s.signedInState);
export const useLastSignIn = () => useUserStore((s) => s.lastSignIn);
export const useUserActions = () => useUserStore((s) => s.actions);

// Update store when user logs in or out
supabase.auth.onAuthStateChange((event, session) => {
  console.log({ event, session });

  // Ignore duplicate SIGNED_IN events
  if (event !== 'SIGNED_IN') {
    lastSignedInEventSessionHash = '';
  } else {
    const sessionHash = simpleHash(JSON.stringify(session));
    if (lastSignedInEventSessionHash === sessionHash) return;
    lastSignedInEventSessionHash = sessionHash;
  }

  const user = sessionToUser(session);
  useUserStore.setState({ user, signedInState: user ? 'signed-in' : 'signed-out' });
  log('AuthStateChange:', event, { ...user, accessToken: '...' });

  if (event === 'PASSWORD_RECOVERY') {
    route('/update-password');
  }

  // When user signs-in or reload page when already signed-in
  if (event === 'SIGNED_IN') {
    // Clear data from prior user if it's still being used
    const priorUserId = getUserDataId();
    if (priorUserId && priorUserId !== user!.id) {
      log('Clearing user & usage data from prior user', priorUserId);
      clearAllUserData();
      usageActions.clearUsageAndSubscriptionData();
    }
    // Clear data from anonymous user unless this is a new account
    if (!priorUserId) {
      const isNewAccount = Date.now() - new Date(user!.createdAt).getTime() < 1000 * 60;
      if (isNewAccount) {
        log('Transferring anonymous user data to new account', user!.id);
      } else {
        log('Clearing user & usage data from anonymous user');
        clearAllUserData();
        usageActions.clearUsageAndSubscriptionData();
      }
    }
    // Update id of user associated with current user data
    setUserDataId(user!.id);
    // Get user data for this user (and sync any updates)
    syncUserDataWithAPI();
    // On iOS Safari, fix height of screen after returning from Google Login
    if (isIOS) {
      const fixHeight = () => {
        if (document.body.getBoundingClientRect().height < innerHeight) {
          log('iOS - fix height after login');
          document.body.style.minHeight = `${innerHeight}px`;
          return true;
        }
        log('iOS - not fixing height after login');
      };
      // Try once now, and then again in a second.
      if (!fixHeight()) setTimeout(fixHeight, 1000);
    }
  }

  // // User was signed out unintentionally
  // if (event === 'SIGNED_OUT' && Date.now() - signOutTime > 1000) {
  //   log('psia - SO');
  //   promptSignInAgain();
  // }
});

// Persist lastSignIn & user to LocalStorage
useUserStore.subscribe(
  (s) => [s.lastSignIn, s.user],
  (after) => {
    const [lastSignIn, user] = after;
    setLazyValue('UserStore__lastSignIn', lastSignIn);
    setLazyValue('UserStore__user', user);
  },
  { equalityFn: (a, b) => JSON.stringify(a) === JSON.stringify(b) }
);

// Log and notify user on authentication-related errors from Supabase
if (typeof window !== 'undefined') {
  requestIdleCallback(() => {
    if (window.location.hash.startsWith('#error=')) {
      const params = new URLSearchParams(window.location.hash.substring(1));
      // @ts-ignore
      const error = Object.fromEntries([...params.entries()]);
      log('Auth error', error);
      if (error && parseInt(error.error_code) === 401 && error.error_description) {
        setTimeout(() => {
          toast.error(error.error_description, {
            duration: 10 * 1000,
          });
        }, 50);
      }
      window.location.hash = '';
    }
  });
}

// Handle error that prevented sign-up / sign-in
{
  const errorName = new URLSearchParams(location.search).get('error');
  if (errorName) {
    const errorDescription = new URLSearchParams(location.search).get(
      'error_description'
    );
    reportError(errorName, errorDescription);
    // Clear parameters from URL after reporting error
    setTimeout(() => {
      window.history.replaceState({}, document.title, window.location.pathname);
    }, 0);
    if (errorDescription) {
      // Let user know with toast
      setTimeout(() => {
        toast.error(`Sign-in failed: ${errorDescription}`, { duration: 10 * 1000 });
      }, 50);
    }
  }
}

{
  //
  // Show user a welcome message (once per device, after first sign-in / sign-up)
  //

  let hasShownMessage = false;
  const localstorageKey = 'ap__shown_welcome_message_at';

  useUserStore.subscribe(
    (s) => s.user && s.user.confirmedAt,
    (confirmedAt) => {
      if (!confirmedAt) return;

      // Have we shown the welcome message in this browser?
      hasShownMessage =
        hasShownMessage || Boolean(safeLocalStorage.getItem(localstorageKey));
      if (hasShownMessage) return;

      // A user has signed-in or signed-up
      const confirmedAgoSeconds = (Date.now() - new Date(confirmedAt).getTime()) / 1000;
      const didUserJustSignUp = confirmedAgoSeconds < 120;

      log(`Showing welcome message to ${didUserJustSignUp ? 'new' : 'existing'} user`);

      showModal('welcome');

      safeLocalStorage.setItem(localstorageKey, Date.now().toString());
    }
  );
}

//
// Utils
//

function sessionToUser(session: Session | null) {
  if (!session || !session.user) return null;

  return {
    id: session.user.id,
    email: session.user.email,
    name: session.user.user_metadata.name,
    provider: session.user.app_metadata.provider,
    pictureUrl: session.user.user_metadata.picture,
    accessToken: session.access_token,
    createdAt: session.user.created_at,
    confirmedAt: session.user.confirmed_at, // Helpful for detecting brand new users
  } as User;
}

function getPostSignInRedirectRoute() {
  const previousRoute = useSessionStore.getState().previousRoute.split(/[?#]/)[0];
  return previousRoute && !/sign-in|sign-up/.test(previousRoute)
    ? previousRoute
    : '/now';
}

function simpleHash(str: string) {
  return hashCode(str).toString();
}

// https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0
function hashCode(s: string): number {
  let h = 0;
  for (let i = 0; i < s.length; i++) h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
  return h;
}
